<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Создать тип документа" size="sm" ok-only ok-title="Добавить"
        hide-footer no-enforce-focus :no-close-on-backdrop="true"
        @hidden="clearData">
            <b-row>
                <b-col cols="12">
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Код</label>
                                <b-input v-model="documentType.code_type_doc" placeholder="Код" />
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Названия</label>
                                <b-input v-model="documentType.name" placeholder="Название" />
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Название для водителя</label>
                                <b-input v-model="documentType.name_for_performer" placeholder="Название для водителя" />
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Название для клиента</label>
                                <b-input v-model="documentType.name_for_client" placeholder="Название для клиента" />
                            </b-col>
                        </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12"><hr></b-col>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    @click="creatDocumentType"
                    :disabled="submitButtonDisabled"
                    class="col-md-5"
                    size="sm"
                    variant="primary">
                        Добавить
                    </b-button>
                    <b-button class="col-md-5" size="sm" variant="secondary" @click="$bvModal.hide($route.name + 'CreateModal')">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
    BRow, BCol,
} from 'bootstrap-vue'
export default {
    components: {
        ToastificationContent,
        vSelect,
        BRow,
        BCol,
    },
    props: ['allowances', 'divisions', 'carCategories', 'category_tariffs'],
    data(){
        return {
            documentType: {
                code_type_doc: null,
                name: null,
                name_for_performer: null,
                name_for_client: null
            },
            submitButtonDisabled: false,
        }
    },
    methods:{
        clearData() {
            this.documentType = {
                code_type_doc: null,
                name: null,
                name_for_performer: null,
                name_for_client: null
            }
        },
        creatDocumentType() {
            const data = JSON.parse(JSON.stringify(this.documentType))
            this.submitButtonDisabled = true
            this.$http.post('type-transaction', data)
            .then(res => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                    title: 'Успех!',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: res.data.message,
                    },
                })
                this.$bvModal.hide(this.$route.name + 'CreateModal')
                this.$emit('refresh')
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
    },
}
</script>